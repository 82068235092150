import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p><em parentName="p">{`Select`}</em>{` is a type of input that is used in forms, where a user is submitting data and chooses one option from a list.`}</p>
    <h3 {...{
      "id": "select-versus-dropdown"
    }}>{`Select versus Dropdown`}</h3>
    <p>{`While the select and dropdown components look similar, they have different functions.`}</p>
    <ul>
      <li parentName="ul">{`Use the select component inside a form where users are selecting from a list of options and submitting data.`}</li>
      <li parentName="ul">{`Use the dropdown component to filter or sort content on a page.`}</li>
    </ul>
    <p>{`Another important difference between the two components is the underlying code. The select component’s appearance will be determined by the browser being used, while the dropdown component can be styled as needed.`}</p>
    <h2 {...{
      "id": "variations"
    }}>{`Variations`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Select type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Purpose`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Default`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Typically used in forms with a variety of other components.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Small select`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When vertical space is a concern, or select is being paired with a larger component like `}<a parentName="td" {...{
              "href": "/components/data-table"
            }}>{`data tables`}</a>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Inline select`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When multiple selects are grouped together.`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "small-select"
    }}>{`Small select`}</h4>
    <p><em parentName="p">{`Small selects`}</em>{` are commonly used in `}<a parentName="p" {...{
        "href": "/components/data-table"
      }}>{`data tables`}</a>{`. When using a small select for a number selection, the increments in the select should be 10, 25, 50 and 100. The maximum amount of items a user can see per page is 100.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.440217391304344%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsSAAALEgHS3X78AAABLklEQVQoz5WSO07EMBCGHfYItFRUIG0Eh+IanGJbCjpqzoDIFomUBkU5QN7vl3DikJh/THa1AQoYafTPjMff2E4YOzHOOfuLSSnPFr2TXyYWlarBtm0mhFCx7/vUqJGnaapZlqU5jqNy13U10zQ1DN5Q7zzPzwSBfqyATdOwsizV1Lqu7xG/FUXxUlWVgTUDNQM1pcj3qO+Rvw7DIBbg/AOIps0CfKKFruskNshxHJWfxriNynHS77xfgY+0ABWozVhTjhPNqKmYlPK+76l1AnAiJT8CwzBUwCzLHnBd6Xlei/fkcRxzAHie5yqOouhQewfw+DFWJ2zbluEDqBiTL7DhNgiCbZIkOuA6Nuuo64Ae9AaDL6dp2i13rumVyFe/AwDsPwbAOWBb6BX8mvwTC0Si/cyhTQAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of a small select",
            "title": "Example of a small select",
            "src": "/static/b009943f2b6a46720a582ec2abe37141/fb070/select-usage-3.png",
            "srcSet": ["/static/b009943f2b6a46720a582ec2abe37141/d6747/select-usage-3.png 288w", "/static/b009943f2b6a46720a582ec2abe37141/09548/select-usage-3.png 576w", "/static/b009943f2b6a46720a582ec2abe37141/fb070/select-usage-3.png 1152w", "/static/b009943f2b6a46720a582ec2abe37141/c3e47/select-usage-3.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <h4 {...{
      "id": "inline-select"
    }}>{`Inline select`}</h4>
    <p>{`Inline select is useful when you have multiple select fields within a form. Inline selects have less visual weight on a page because they are borderless.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30.434782608695656%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsSAAALEgHS3X78AAAAyElEQVQY041QywqDMBDUxHMP/ZQeKkKP/Wu/QHsRxBfqwRAK8f3Wm90ELBa0OLBMsswMuytJGyzL8mUomdc0TXKapqhtW1QUBSrLEtV1LXddJ7QrH2INPYNxHP8LHMcR7Pu+Ytv2w3XdJ6VUq6pKhQnvMJ0K02lN09yGYVC4tu/740C+Cud5ni9Zlpl5nr8ZYzGsTCE0hjeBPoPgF/yvW88uLMsSrOu6lCQJ9jwPgxnD7XAQBDgMQ9EjhGDDMH48h4ii6PQN97Qf8myWzeiW9fIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of a small select",
            "title": "Example of a small select",
            "src": "/static/59260c674bf6b532d1f9827dbddf5d19/fb070/select-usage-4.png",
            "srcSet": ["/static/59260c674bf6b532d1f9827dbddf5d19/d6747/select-usage-4.png 288w", "/static/59260c674bf6b532d1f9827dbddf5d19/09548/select-usage-4.png 576w", "/static/59260c674bf6b532d1f9827dbddf5d19/fb070/select-usage-4.png 1152w", "/static/59260c674bf6b532d1f9827dbddf5d19/c3e47/select-usage-4.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <h2 {...{
      "id": "general-guidelines"
    }}>{`General guidelines`}</h2>
    <h3 {...{
      "id": "labels"
    }}>{`Labels`}</h3>
    <p>{`Labels are essential to the usability of forms. Do not place a label inside a `}<em parentName="p">{`select`}</em>{` element. Use sentence case and no more than three words.`}</p>
    <h3 {...{
      "id": "validation"
    }}>{`Validation`}</h3>
    <p>{`Real-time validation helps to streamline the process and keep data clean when the user is filling out forms. For full guidelines, refer to the `}<a parentName="p" {...{
        "href": "/components/form"
      }}>{`forms`}</a>{` usage page.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.016304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsSAAALEgHS3X78AAAA+0lEQVQoz2NgQAMn1q2H0KvWMJ7atp3x6OYtjPdPnmL8fPMW47tLlxnfnTvPcBKq9nnPRAaiwIV1GwiqOcpAJNgwdTqYXtM/QXTP4qWKm+bNV3p0+KjY9/MXBT88fCT84fBRvm1QtU+bOwkb+OTsORYQ/eL02Rn3L176df/SpecPL11++f7hw6dvT535+Pb6jX3XGBjAap5PnkHYwKfTZzOC6GdTZ+o9nD3P59GqtXZAF5oBDTN/s2ef5evDR3WvMjBA1LT1EOft558+ElRzk9gwfPfjO5j+8OkT45v//xlf3rnL+PzAIaa3J04xvQHSQMx4Aar2WQtmGAIAgvV8/1hyQj4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of a small select",
            "title": "Example of a small select",
            "src": "/static/da54c27474ff47cbc31b6d8b7a39a863/fb070/select-usage-1.png",
            "srcSet": ["/static/da54c27474ff47cbc31b6d8b7a39a863/d6747/select-usage-1.png 288w", "/static/da54c27474ff47cbc31b6d8b7a39a863/09548/select-usage-1.png 576w", "/static/da54c27474ff47cbc31b6d8b7a39a863/fb070/select-usage-1.png 1152w", "/static/da54c27474ff47cbc31b6d8b7a39a863/c3e47/select-usage-1.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <h3 {...{
      "id": "order"
    }}>{`Order`}</h3>
    <p>{`The order of the select list should be based on the frequency of use. If applicable, the list should be in increasing order relative to the content. In cases of forms, alternative orders such as alphabetical may be more fitting. A horizontal rule can be used to group similar items together.`}</p>
    <h2 {...{
      "id": "accessibility"
    }}>{`Accessibility`}</h2>
    <h3 {...{
      "id": "styling-select-dropdowns"
    }}>{`Styling select dropdowns`}</h3>
    <p>{`To maximize accessibility, it is strongly recommended not to style select dropdowns.
One of the `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/WCAG20-TECHS/G202.html"
      }}>{`WCAG 2.0 standards`}</a>{` for accessibility is “ensuring keyboard control for all functionality.” CSS alternatives to select elements do not meet this requirement.`}</p>
    <p>{`While you can make a select element easily usable by a mouse, making it usable with keyboard navigation is complex. The default select element should follow this process:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`State`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Mouse`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Keyboard`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`:hover`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`move your cursor over the select element`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`use the tab key to focus the select element`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`:focus`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`click on the select element`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`press enter`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`:`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`move your cursor over the desired option`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`use the top and bottom arrow keys to pick an option`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`:select`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`click on the desired option`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`press enter`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      